import React, { useState, useEffect } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Menu from "./Menu";
let debounceTimer;
function BlogHeroSection({ title, subTitle, bgImage, showCorLeft }) {
  let logoFawesome = "./assets/images/logo-fawesome-tv.svg";
  let searchIcon = "./assets/images/search.svg";
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const navLinks = document.querySelectorAll(".bg-body-tertiary .nav-link");
    const searchInput = document.querySelectorAll(
      ".bg-body-tertiary #search-input"
    );
    navLinks.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-nav-link");
    });
    searchInput.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-search-text");
    });
  }, []);

  return (
    <>
      <Menu></Menu>
      <div className="heroSection blog" style={getBgStyle()}>
        {showCorLeft ? <div className="fCorLeftBlog"></div> : ""}
        <div className="herotitleContBlog">
          <h1 className="herotitle">{title}</h1>
          <h3 className="herotitleSub">{subTitle}</h3>
        </div>
      </div>
    </>
  );

  function handleClick() {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    var newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    var homePath = "/home/";
    if (newUrl.endsWith("/")) {
      homePath = "home/";
    }
    window.location.href = newUrl + homePath + qs;
  }

  function isValidInputForSearch(str) {
    // Regex allows input starting with letters or numbers, followed by any characters except %/\?
    return /^[a-zA-Z0-9](?!.*[%\/\\?]).*$/.test(str);
  }

  function handleChange(event) {
    var currentValue = event.target.value;
    if (!isValidInputForSearch(currentValue)) {
      event.target.value = currentValue.slice(0, -1); // Remove the last character if invalid
    }
    var searchText = event.target.value;
    setInputValue(searchText);
    // Set debounce delay in milliseconds
    var debounceDelay = 2000;
    // Check if searchText has at least 2 letters
    if (searchText.length >= 2) {
      // Clear previous debounce timer
      clearTimeout(debounceTimer);
      // Set new debounce timer
      debounceTimer = setTimeout(function () {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }, debounceDelay);
    }
  }

  function handleKeyDown(event) {
    var searchText = event.target.value;
    clearTimeout(debounceTimer);
    if (event.key === "Enter") {
      if (searchText.length >= 2) {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }
    }
  }
  function getBgStyle() {
    if (bgImage) {
      return {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    }
    return {};
  }
}
export default BlogHeroSection;
